// @ts-nocheck
import React, { createContext, useEffect, useState, useContext, useRef } from 'react';
import { useAuth } from '@workos-inc/authkit-react';
import { decodeJwt } from 'jose';
import { onSnapshot, doc } from 'firebase/firestore';

import { firestore } from '../firebase';
import { signInWithCustomToken } from '../services/authentication';

const UserContext = createContext();

// eslint-disable-next-line react/display-name
export const withUserContext = (Component) => (props) =>
  (
    <UserContext.Consumer>
      {(value) => <Component {...value} {...props} />}
    </UserContext.Consumer>
  );

// import entitlements from '../util/entitlements';

export const UserContextProvider = ({ children }) => {
  const { isLoading, signIn, signUp, signOut, user, getAccessToken } =
    useAuth();
  const [updatedUser, setUpdatedUser] = useState();
  const [userData, setUserData] = useState();
  const [triggerReload, setTriggerReload] = useState(false);

  const userDocumentSnapshotListener = useRef();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    setUpdatedUser(user);

    if (!user) {
      return;
    }

    const getUserData = async () => {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        console.error('no access token');
        return;
      }

      try {
        const userOrgQuery = await fetch(
          `${import.meta.env.VITE_API_BASE_URI}/auth/user-org`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        const userOrgResp = await userOrgQuery.json();

        if (userOrgResp.err) {
          throw new Error(userOrgResp.err);
        }

        if (userOrgResp.firebase_token) {
          await signInWithCustomToken(userOrgResp.firebase_token);

          if (!userDocumentSnapshotListener.current) {
            // The user is signed in, begin retrieval of external user data.
            if (typeof userDocumentSnapshotListener.current === 'function') {
              userDocumentSnapshotListener.current();
            }
            userDocumentSnapshotListener.current = onSnapshot(
              doc(firestore, 'users', userOrgResp.uid), // if we have firebase_token, we have uid
              async (snapshot) => {
                const data = snapshot.data();
                // The user doesn’t have a data point, equivalent to not signed in.
                // Or the user signed in anonymously
                if (!snapshot.exists() || !data) {
                  return;
                }

                setUserData(data);
              },
              (error) => {
                console.error(error);
              },
            );
          }
        }
        const decodedToken = decodeJwt(accessToken);

        setUpdatedUser({
          ...user,
          uid: userOrgResp?.uid,
          orgId: userOrgResp?.orgId,
          wosOrgId: userOrgResp?.wosOrgId,
          firebase_token: userOrgResp?.firebase_token,
          accessToken,
          decodedToken,
        });
      } catch (err) {
        console.error(err);
      }
    };
    getUserData();
  }, [isLoading, user, getAccessToken, triggerReload]);

  return (
    <UserContext.Provider
      value={{
        isLoading,
        signIn,
        signUp,
        signOut,
        user: updatedUser,
        userData,
        refreshTokens: () => setTriggerReload(!triggerReload),
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
