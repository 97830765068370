// @ts-nocheck
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import validate from 'validate.js';

import withStyles from '@mui/styles/withStyles';

import { Dialog, DialogTitle, Tooltip, IconButton } from '@mui/material';

import { Close as CloseIcon } from '@mui/icons-material';

import constraints from '../../data/constraints';
import { signInWithCustomToken } from '../../services/authentication';
import { create } from '../../services/organisations';

import { functions, firestore } from '../../firebase';
import { httpsCallable } from 'firebase/functions';
import { doc, updateDoc } from 'firebase/firestore';

import OrgSignupInitialStep from '../OrgSignupInitialStep';
import OrgSignupNewOrgStep from '../OrgSignupNewOrgStep';
import OrgSignupJoinOrgStep from '../OrgSignupJoinOrgStep';

import { withUserContext } from '../../contexts/UserContext';

const addOrgIdToUser = httpsCallable(functions, 'addOrgIdClaimToToken');

const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },

  icon: {
    marginRight: theme.spacing(0.5),
  },

  divider: {
    margin: 'auto',
  },

  grid: {
    marginBottom: theme.spacing(2),
  },
});

const initialState = {
  performingAction: false,
  organisation: '',
  // subdomain: "",
  errors: null,
  organisationId: '',
  linkCode: '',
  currentStep: 'initial',
};

class OrganisationSignupDialog extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  signUpOrganisation = (event) => {
    const {
      organisation,
      // subdomain,
      currentStep,
    } = this.state;

    if (currentStep === 'initial') {
      return;
    }

    if (!organisation) {
      return;
    }

    const errors = validate(
      {
        organisation,
        // subdomain,
      },
      {
        organisation: constraints.organisation,
        // subdomain: constraints.subdomain,
      },
    );

    if (errors) {
      this.setState({
        errors: errors,
      });
    } else {
      this.setState(
        {
          performingAction: true,
        },
        async () => {
          //create the organisation
          try {
            const orgDoc = await create({
              organisation,
            });

            const { data } = await addOrgIdToUser({
              userId: this.props.user?.id,
              orgId: orgDoc.id,
              orgName: organisation,
            });
            await signInWithCustomToken(data.customToken);
            this.props.refreshTokens();
            await this.createStripeCustomer(orgDoc.id);

            //refresh the auth token
            await this.props.onOrgLink();

            this.props.dialogProps.onCloseDialog(() => {
              this.props.openSnackbar(
                `Created and Linked Organisation Successfully`,
              );
            });
          } catch (reason) {
            const code = reason.code;
            const message = reason.message;

            switch (code) {
              case 'auth/invalid-email':
              case 'auth/missing-android-pkg-name':
              case 'auth/missing-continue-uri':
              case 'auth/missing-ios-bundle-id':
              case 'auth/invalid-continue-uri':
              case 'auth/unauthorized-continue-uri':
              case 'auth/user-not-found':
                this.props.openSnackbar(message);
                return;

              default:
                this.props.openSnackbar(message);
                return;
            }
          } finally {
            this.setState({
              performingAction: false,
            });
          }
        },
      );
    }
  };

  createStripeCustomer = async (orgId) => {
    const { organisation } = this.state;
    const { user } = this.props;

    // First, create customer in Stripe...
    const createStripeCustomer = httpsCallable(
      functions,
      'stripe-createStripeCustomer',
    );

    try {
      if (!user?.email || !organisation || !orgId) {
        throw new Error('Missing user and org info');
      }

      const stripeCustomerObj = await createStripeCustomer({
        email: user.email,
        organisation,
        orgId,
        isNewCustomer: true,
      });

      const stripeCustomerId = stripeCustomerObj.data.id;

      // ...then, add it to firestore.
      if (stripeCustomerId) {
        const organisationRef = doc(firestore, 'organisations', orgId);

        const stripeLinkUrl = `https://dashboard.stripe.com/${
          process.env.VITE_FIREBASE_PROJECT_ID === 'broadcaster-629f3'
            ? ''
            : 'test/'
        }customers/${stripeCustomerId}`;

        await updateDoc(organisationRef, {
          stripeId: stripeCustomerId,
          stripeLink: stripeLinkUrl,
          isNewCustomer: true,
        });
      }

      return stripeCustomerObj;
    } catch (reason) {
      this.props.openSnackbar(reason.message);
    }
  };

  linkOrganisation = (event) => {
    const { organisationId, linkCode, currentStep } = this.state;

    if (currentStep === 'initial') {
      return;
    }

    if (!(organisationId && linkCode)) {
      return;
    }

    const errors = validate(
      {
        organisationId,
        linkCode,
      },
      {
        organisationId: constraints.organisationId,
        linkCode: constraints.linkCode,
      },
    );

    if (errors) {
      this.setState({
        errors: errors,
      });
    } else {
      this.setState(
        {
          performingAction: true,
        },
        async () => {
          try {
            const { data } = await addOrgIdToUser({
              userId: this.props.user?.id,
              orgId: organisationId,
              linkCode: linkCode,
            });
            await signInWithCustomToken(data.customToken);
            this.props.dialogProps.onCloseDialog(() => {
              this.props.openSnackbar(`Link Successful`);
            });
            this.props.onOrgLink();
          } catch (reason) {
            const code = reason.code;
            const message = reason.message;

            switch (code) {
              case 'auth/invalid-email':
              case 'auth/missing-android-pkg-name':
              case 'auth/missing-continue-uri':
              case 'auth/missing-ios-bundle-id':
              case 'auth/invalid-continue-uri':
              case 'auth/unauthorized-continue-uri':
              case 'auth/user-not-found':
                this.props.openSnackbar(message);
                return;

              default:
                this.props.openSnackbar(message);
                return;
            }
          } finally {
            this.setState({
              performingAction: false,
            });
          }
        },
      );
    }
  };

  handleKeyPress = (event) => {
    const {
      // subdomain,
      currentStep,
    } = this.state;

    const key = event.key;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === 'Enter') {
      switch (currentStep) {
        case 'initial':
          return;
        case 'setupNewOrg':
          this.signUpOrganisation();
          break;
        case 'joinOrg':
          this.linkOrganisation();
          break;
        default:
          break;
      }
    }
  };

  handleExited = () => {
    this.setState(initialState);
  };

  handleOrganisationChange = (event) => {
    const organisation = event.target.value;

    this.setState({
      organisation: organisation,
    });
  };

  // handleSubdomainChange = (event) => {
  //   const subdomain = event.target.value;

  //   this.setState({
  //     subdomain: subdomain,
  //   });
  // };

  handleOrganisationIdChange = (event) => {
    const organisationId = event.target.value;

    this.setState({
      organisationId: organisationId,
    });
  };

  handleLinkCodeChange = (event) => {
    const linkCode = event.target.value;

    this.setState({
      linkCode: linkCode,
    });
  };

  showInitialStep = () => {
    this.setState(initialState);
  };

  showOrgSetupStep = () => {
    this.setState({
      currentStep: 'setupNewOrg',
    });
  };

  showJoinOrgStep = () => {
    this.setState({
      currentStep: 'joinOrg',
    });
  };

  render() {
    // Styling
    const { classes } = this.props;

    // Dialog Properties
    const { dialogProps } = this.props;
    const { onCloseDialog, ...passedDialogProps } = dialogProps;

    const {
      performingAction,
      organisation,
      // subdomain,
      organisationId,
      linkCode,
      errors,
      currentStep,
    } = this.state;

    return (
      <Dialog
        fullWidth
        maxWidth='sm'
        {...passedDialogProps}
        onKeyPress={this.handleKeyPress}
        TransitionProps={{
          onExited: this.handleExited,
        }}
        onClose={(event, reason) => {
          if (performingAction) {
            return false;
          }
          onCloseDialog(event, reason);
        }}
      >
        <DialogTitle>
          Setup your organisation
          <Tooltip title='Close'>
            <IconButton
              className={classes.closeButton}
              // disabled={performingAction}
              onClick={onCloseDialog}
              size='large'
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        {currentStep === 'initial' ? (
          <OrgSignupInitialStep
            performingAction={performingAction}
            showOrgSetupStep={this.showOrgSetupStep}
            showJoinOrgStep={this.showJoinOrgStep}
          />
        ) : currentStep === 'setupNewOrg' ? (
          <OrgSignupNewOrgStep
            performingAction={performingAction}
            organisation={organisation}
            errors={errors}
            showInitialStep={this.showInitialStep}
            handleOrganisationChange={this.handleOrganisationChange}
            signUpOrganisation={this.signUpOrganisation}
          />
        ) : currentStep === 'joinOrg' ? (
          <OrgSignupJoinOrgStep
            performingAction={performingAction}
            organisationId={organisationId}
            linkCode={linkCode}
            errors={errors}
            showInitialStep={this.showInitialStep}
            handleOrganisationIdChange={this.handleOrganisationIdChange}
            handleLinkCodeChange={this.handleLinkCodeChange}
            linkOrganisation={this.linkOrganisation}
          />
        ) : null}
      </Dialog>
    );
  }
}

OrganisationSignupDialog.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Dialog Properties
  dialogProps: PropTypes.object.isRequired,

  // Custom Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(withUserContext(OrganisationSignupDialog));
