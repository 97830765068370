// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';
import 'typeface-roboto';
import './index.css';
import App from './components/App';
import posthog from 'posthog-js';
import { AuthKitProvider } from '@workos-inc/authkit-react';
import { UserContextProvider } from './contexts/UserContext';

posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, {
  api_host: import.meta.env.VITE_POSTHOG_URI,
  ui_host: 'eu.posthog.com',
});

if (!window.location.origin.includes('localhost')) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: `${import.meta.env.VITE_NAME}@${import.meta.env.VITE_VERSION}`,
  });
}
ReactDOM.render(
  <React.StrictMode>
    <AuthKitProvider
      clientId={import.meta.env.VITE_WORKOS_CLIENT_ID}
      devMode={true}
    >
      <UserContextProvider>
        <App />
      </UserContextProvider>
    </AuthKitProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
serviceWorker.unregister();
