// @ts-nocheck
import React, { Component, Fragment } from 'react';

import readingTime from 'reading-time';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import withStyles from '@mui/styles/withStyles';

import { CssBaseline, Button, Snackbar } from '@mui/material';

import { firestore } from '../../firebase';
import { collection, onSnapshot, doc, query, where } from 'firebase/firestore';
import {
  deleteAccount,
  // signOut,
  getOrgId,
  getRoles,
  signInWithCustomToken,
} from '../../services/authentication';
import { defaultTheme, createTheme } from '../../services/appearance';
import AppProvider from '../../contexts/AppContext';
import SubscriptionProvider from '../../contexts/SubscriptionContext';
import { withUserContext } from '../../contexts/UserContext';

import ErrorBoundary from '../ErrorBoundary';
import LaunchScreen from '../LaunchScreen';
import Bar from '../Bar';
import Router from '../Router';
import DialogHost from '../DialogHost';

const styles = (theme) => ({
  base: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
});

const initialState = {
  ready: false,
  performingAction: false,
  theme: defaultTheme,
  user: null,
  userData: null,
  roles: [],
  logoURL: '',
  isInIFrame: true,
  orgSubscription: null,

  aboutDialog: {
    open: false,
  },

  signUpDialog: {
    open: false,
  },

  signInDialog: {
    open: false,
  },

  settingsDialog: {
    open: false,
  },

  deleteAccountDialog: {
    open: false,
  },

  signOutDialog: {
    open: false,
  },

  createRoomDialog: {
    open: false,
  },

  createBridgeDialog: {
    open: false,
  },

  organisationSignupDialog: {
    open: false,
  },

  endTrialDialog: {
    open: false,
  },

  snackbar: {
    autoHideDuration: 0,
    message: '',
    open: false,
  },
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  resetState = (callback) => {
    this.setState(
      {
        ready: true,
        theme: defaultTheme,
        // user: null,
        userData: null,
        // roles: [],
        logoURL: '',
      },
      callback,
    );
  };

  setTheme = (theme, callback) => {
    if (!theme) {
      this.setState(
        {
          theme: defaultTheme,
        },
        callback,
      );

      return;
    }

    this.setState(
      {
        theme: createTheme(theme),
      },
      callback,
    );
  };

  openDialog = (dialogId, callback) => {
    const dialog = this.state[dialogId];
    if (!dialog || dialog.open === undefined || null) {
      return;
    }

    dialog.open = true;

    this.setState({ dialog }, callback);
  };

  closeDialog = (dialogId, callback) => {
    const dialog = this.state[dialogId];

    if (!dialog || dialog.open === undefined || null) {
      return;
    }

    dialog.open = false;

    this.setState({ dialog }, callback);
  };

  closeAllDialogs = (callback) => {
    this.setState(
      {
        aboutDialog: {
          open: false,
        },

        signUpDialog: {
          open: false,
        },

        signInDialog: {
          open: false,
        },

        settingsDialog: {
          open: false,
        },

        deleteAccountDialog: {
          open: false,
        },

        signOutDialog: {
          open: false,
        },

        createRoomDialog: {
          open: false,
        },

        createBridgeDialog: {
          open: false,
        },

        organisationSignupDialog: {
          open: false,
        },

        endTrialDialog: {
          open: false,
        },
      },
      callback,
    );
  };

  deleteAccount = () => {
    this.setState(
      {
        performingAction: true,
      },
      () => {
        deleteAccount()
          .then(() => {
            this.closeAllDialogs(() => {
              this.openSnackbar('Deleted account');
            });
          })
          .catch((reason) => {
            const code = reason.code;
            const message = reason.message;

            switch (code) {
              default:
                this.openSnackbar(message);
                return;
            }
          })
          .finally(() => {
            this.setState({
              performingAction: false,
            });
          });
      },
    );
  };

  signOut = (callback) => {
    this.setState(
      {
        performingAction: true,
        user: null,
      },
      () => {
        this.props
          .signOut()
          .then(() => {
            this.closeAllDialogs(() => {
              this.openSnackbar('Signed out');
            });

            if (typeof this.unsubscribeOrgListener === 'function') {
              this.unsubscribeOrgListener();
              this.unsubscribeOrgListener = null;
            }

            if (typeof this.unsubscribeSubscriptionsListener === 'function') {
              this.unsubscribeSubscriptionsListener();
              this.unsubscribeSubscriptionsListener = null;
            }

            if (callback && typeof callback === 'function') {
              callback();
            }
          })
          .catch((reason) => {
            const code = reason.code;
            const message = reason.message;

            switch (code) {
              default:
                this.openSnackbar(message);
                return;
            }
          })
          .finally(() => {
            this.setState({
              performingAction: false,
            });
          });
      },
    );
  };

  openSnackbar = (message, autoHideDuration = 2, action, callback) => {
    this.setState(
      {
        snackbar: {
          autoHideDuration:
            autoHideDuration === null
              ? null
              : readingTime(message).time * autoHideDuration,
          message,
          open: true,
          action: action,
        },
      },
      () => {
        if (callback && typeof callback === 'function') {
          callback();
        }
      },
    );
  };

  closeSnackbar = (clearMessage = false) => {
    const { snackbar } = this.state;

    this.setState({
      snackbar: {
        message: clearMessage ? '' : snackbar.message,
        open: false,
      },
    });
  };

  setLogo = (url) => {
    this.setState({
      logoURL: url,
    });
  };

  onEndTrialClick = () => {
    this.openDialog('endTrialDialog');
  };

  render() {
    const { ready, performingAction, theme, userData, roles, logoURL } =
      this.state;

    const {
      aboutDialog,
      signUpDialog,
      signInDialog,
      settingsDialog,
      deleteAccountDialog,
      signOutDialog,
      createRoomDialog,
      createBridgeDialog,
      organisationSignupDialog,
      endTrialDialog,
      isInIFrame,
      orgSubscription,
    } = this.state;

    const { snackbar } = this.state;

    const { user, classes } = this.props;

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <AppProvider>
            <SubscriptionProvider orgSubscription={orgSubscription}>
              <div className={classes.base} key={user?.orgId || 1}>
                <ErrorBoundary>
                  {!ready && <LaunchScreen />}

                  {ready && (
                    <>
                      <Router
                        roles={roles}
                        isInIFrame={isInIFrame}
                        setTheme={this.setTheme}
                        setLogo={this.setLogo}
                        signOut={this.signOut}
                        onEndTrialClick={this.onEndTrialClick}
                        bar={
                          <Fragment>
                            <Bar
                              performingAction={performingAction}
                              theme={theme}
                              logoURL={logoURL}
                              roles={roles}
                              onSignUpClick={() =>
                                this.openDialog('signUpDialog')
                              }
                              // onSignInClick={() =>
                              //   this.openDialog('signInDialog')
                              // }
                              onAboutClick={() =>
                                this.openDialog('aboutDialog')
                              }
                              onSettingsClick={() =>
                                this.openDialog('settingsDialog')
                              }
                              onSignOutClick={() =>
                                this.openDialog('signOutDialog')
                              }
                            />
                            <DialogHost
                              performingAction={performingAction}
                              theme={theme}
                              openSnackbar={this.openSnackbar}
                              logoURL={logoURL}
                              setLogo={this.setLogo}
                              dialogs={{
                                organisationSignupDialog: {
                                  dialogProps: {
                                    open: organisationSignupDialog.open,
                                    onCloseDialog: (callback) => {
                                      this.closeDialog(
                                        'organisationSignupDialog',
                                      );

                                      if (
                                        callback &&
                                        typeof callback === 'function'
                                      ) {
                                        callback();
                                      }
                                    },
                                  },
                                  props: {
                                    onOrgLink: () => {
                                      getRoles().then((value) => {
                                        getOrgId().then((orgId) => {
                                          this.setState(
                                            (prevState) => {
                                              // console.log(
                                              //   'prevState!!!',
                                              //   prevState
                                              // );
                                              // console.log(
                                              //   'setting data now that we have an org Id',
                                              //   {
                                              //     ready: true,
                                              //     user: {
                                              //       ...prevState.user,
                                              //       orgId,
                                              //     },
                                              //     roles: value || [],
                                              //   }
                                              // );

                                              return {
                                                ready: true,
                                                user: {
                                                  ...prevState.user,
                                                  orgId,
                                                },
                                                roles: value || [],
                                              };
                                            },
                                            // () => {
                                            //   console.log(
                                            //     'setState',
                                            //     this.state
                                            //   );
                                            // }
                                          );
                                        });
                                      });
                                    },
                                  },
                                },

                                createRoomDialog: {
                                  dialogProps: {
                                    open: createRoomDialog.open,

                                    onCloseDialog: () =>
                                      this.closeDialog('createRoomDialog'),
                                  },
                                },

                                createBridgeDialog: {
                                  dialogProps: {
                                    open: createBridgeDialog.open,

                                    onCloseDialog: () =>
                                      this.closeDialog('createBridgeDialog'),
                                  },
                                },

                                aboutDialog: {
                                  dialogProps: {
                                    open: aboutDialog.open,

                                    onCloseDialog: () =>
                                      this.closeDialog('aboutDialog'),
                                  },
                                },

                                signUpDialog: {
                                  dialogProps: {
                                    open: signUpDialog.open,
                                    onCloseDialog: (callback) => {
                                      this.closeDialog('signUpDialog');

                                      if (
                                        callback &&
                                        typeof callback === 'function'
                                      ) {
                                        callback();
                                      }
                                    },
                                  },
                                },

                                signInDialog: {
                                  dialogProps: {
                                    open: signInDialog.open,

                                    onClose: (callback) => {
                                      this.closeDialog('signInDialog');

                                      if (
                                        callback &&
                                        typeof callback === 'function'
                                      ) {
                                        callback();
                                      }
                                    },
                                  },
                                },

                                settingsDialog: {
                                  dialogProps: {
                                    open: settingsDialog.open,

                                    onClose: () =>
                                      this.closeDialog('settingsDialog'),
                                  },

                                  props: {
                                    onDeleteAccountClick: () =>
                                      this.openDialog('deleteAccountDialog'),

                                    onOrgSignupClick: () =>
                                      this.openDialog(
                                        'organisationSignupDialog',
                                        this.closeDialog('settingsDialog'),
                                      ),

                                    onEndTrialClick: this.onEndTrialClick,

                                    endTrialEnabled:
                                      orgSubscription?.status === 'trialing',
                                  },
                                },

                                deleteAccountDialog: {
                                  dialogProps: {
                                    open: deleteAccountDialog.open,

                                    onClose: () =>
                                      this.closeDialog('deleteAccountDialog'),
                                  },

                                  props: {
                                    deleteAccount: this.deleteAccount,
                                  },
                                },

                                endTrialDialog: {
                                  dialogProps: {
                                    open: endTrialDialog.open,

                                    onClose: () =>
                                      this.closeDialog('endTrialDialog'),
                                  },
                                },

                                signOutDialog: {
                                  dialogProps: {
                                    open: signOutDialog.open,

                                    onClose: () =>
                                      this.closeDialog('signOutDialog'),
                                  },

                                  props: {
                                    title: 'Log out?',
                                    contentText:
                                      'While logged out you are unable to manage your profile and conduct other activities that require you to be logged in.',
                                    dismissiveAction: (
                                      <Button
                                        color='primary'
                                        onClick={() =>
                                          this.closeDialog('signOutDialog')
                                        }
                                      >
                                        Cancel
                                      </Button>
                                    ),
                                    confirmingAction: (
                                      <Button
                                        color='primary'
                                        disabled={performingAction}
                                        variant='contained'
                                        onClick={this.signOut}
                                      >
                                        Log Out
                                      </Button>
                                    ),
                                  },
                                },
                              }}
                            />
                          </Fragment>
                        }
                        openSnackbar={this.openSnackbar}
                        onCreateRoomClick={() =>
                          this.openDialog('createRoomDialog')
                        }
                        onCreateBridgeClick={() =>
                          this.openDialog('createBridgeDialog')
                        }
                        onSignUpClick={() => this.openDialog('signUpDialog')}
                      />

                      <Snackbar
                        autoHideDuration={snackbar.autoHideDuration}
                        message={snackbar.message}
                        open={snackbar.open}
                        onClose={this.closeSnackbar}
                        action={snackbar.action}
                      />
                    </>
                  )}
                </ErrorBoundary>
              </div>
            </SubscriptionProvider>
          </AppProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

  componentDidMount() {
    this.setState({
      isInIFrame: window.self !== window.top,
      ready: true,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { user } = this.props;
    const { user: prevUser } = prevProps;

    if (!user) {
      return;
    }

    if (!user.orgId && !this.state.organisationSignupDialog.open) {
      // The user doesn't have an orgId yet, so they should sign up to one.
      this.openDialog('organisationSignupDialog');
      return;
    }

    if (user.orgId !== prevUser?.orgId && !this.unsubscribeOrgListener) {
      const { orgId } = user;

      try {
        if (typeof this.unsubscribeOrgListener === 'function') {
          this.unsubscribeOrgListener();
        }
        this.unsubscribeOrgListener = onSnapshot(
          doc(firestore, 'organisations', orgId),
          async (orgSnap) => {
            const orgData = orgSnap.data();
            if (!orgSnap.exists() || !orgData) {
              return;
            }

            const { subscription: subscriptionData, organisation } = orgData;

            this.setTheme(orgData.theme, () => {
              this.setState((prevState) => {
                return {
                  user: {
                    ...prevState.user,
                    orgId,
                    organisation,
                  },
                  logoURL: orgData.logoURL,
                };
              });
            });

            if (typeof this.unsubscribeSubscriptionsListener === 'function') {
              this.unsubscribeSubscriptionsListener();
            }
            this.unsubscribeSubscriptionsListener = onSnapshot(
              query(
                collection(orgSnap.ref, 'subscriptions'),
                where('status', 'in', ['active', 'trialing', 'past_due']),
              ),
              async (querySnapshot) => {
                let currentSub;

                if (querySnapshot.docs.length > 0) {
                  const bandwidthUsageRecord =
                    subscriptionData?.bandwidthUsage || {};

                  let bandwidthUsageGB = 0;
                  if (subscriptionData?.bandwidthUsageGBTotal) {
                    bandwidthUsageGB = subscriptionData.bandwidthUsageGBTotal;
                  } else {
                    const metricsArray = Object.values(bandwidthUsageRecord);

                    if (metricsArray.length) {
                      bandwidthUsageGB = metricsArray.reduce((prev, curr) => {
                        return prev + curr;
                      });
                      // bandwidthUsageGB =
                      //   Math.round(bandwidthUsageGB * 1000) / 1000;
                    }
                  }

                  currentSub = {
                    id: querySnapshot.docs[0].id,
                    stripeCustomerId: orgData.stripeId,
                    bandwidthUsage: bandwidthUsageGB,
                    bandwidthLimit: subscriptionData?.bandwidthLimit,
                    ...querySnapshot.docs[0].data(),
                  };
                }
                this.setState({
                  ready: true,
                  orgSubscription: currentSub,
                });
              },
            );
          },
        );
      } catch (reason) {
        this.resetState(() => {
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            default:
              this.openSnackbar(message);
              return;
          }
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.onAuthStateChangedObserver) {
      this.onAuthStateChangedObserver();
    }

    if (this.userDocumentSnapshotListener) {
      this.userDocumentSnapshotListener();
    }

    if (this.onAuthIdTokenChangedObserver) {
      this.onAuthIdTokenChangedObserver();
    }

    if (typeof this.unsubscribeOrgListener === 'function') {
      this.unsubscribeOrgListener();
    }

    if (typeof this.unsubscribeSubscriptionsListener === 'function') {
      this.unsubscribeSubscriptionsListener();
    }
  }
}

export default withStyles(styles)(withUserContext(App));
